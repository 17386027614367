/* eslint-disable */
import React from "react";
import { Link, Route, useNavigate } from "react-router-dom";
import { MY_ACCOUNT, LOGOUT_API_URL, ABOUT_URL } from "../../Shared/constant";
import { useDispatch } from "react-redux";
import { getAccessToken, checkLogin, accountNavigate } from "../../../redux/actions";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CommonDropdown = (props) => {
    const { setDropdwon, accessToken } = props;
    const dispatch = useDispatch();
    const Navigate = useNavigate();

    // logout function
    const Logout = () => {
        window.axios.get(LOGOUT_API_URL, {
            headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
            }
        }).then(function (result) {
            toast.success(result.data.msg);
            AccessTokenCheckLogout();
        })  
        .catch(function (result) {
            // toast.error(result.response.data.detail);
            // if(result.response.data.detail === "Token expired.") {
                AccessTokenCheckLogout();
            // };
        });
    };

    // if accessToken expire then page will logut and redirect to home page 
    const AccessTokenCheckLogout = () => {
        document.getElementById("appbody").classList.remove("loginModal");
        if(screen.width < 800) {
            var element = document.getElementById("responsiveSidebarMain");
            element.classList.remove("active");
        }
        setTimeout(() =>  Navigate(ABOUT_URL), 200);
        localStorage.removeItem("accessToken");
        dispatch(getAccessToken(""));
        dispatch(checkLogin("no"));
        setDropdwon(false);
    };

    // my book link route
    const MyBookLink = () => {
        dispatch(accountNavigate("my-books"));
        setDropdwon(false);
        if(screen.width < 800) {
            var element = document.getElementById("responsiveSidebarMain");
            element.classList.remove("active");
        }
    };

    // my account link route
    const MyAccountLink = () => {
        dispatch(accountNavigate("my-account"));
        setDropdwon(false);
        if(screen.width < 800) {
            var element = document.getElementById("responsiveSidebarMain");
            element.classList.remove("active");
        }
    };

    return(<div className="CommonDropdownWraps">
        <ul>
            <li>
                <Link to={MY_ACCOUNT} onClick={() => MyBookLink()}>My Books</Link>
            </li>
            <li>
                <Link to={MY_ACCOUNT} onClick={() => MyAccountLink()}>My Account</Link>
            </li>
            <li>
                <a onClick={() => Logout()} >Logout</a>
            </li>
        </ul>
    </div>);
}

export default CommonDropdown;