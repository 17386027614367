/* eslint-disable */
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PRIVACY_POLICY, TERMS_CONDITIONS, REFUND_POLICY, CONTACT, SUBSCRIBE_API_URL } from "./constant";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Footer = () => {
    const [ email, setEmail ] = useState("");
    const [ errorEmail, setErrorEmail] = useState("");
    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;

    // Subscribe button function
    const SubscribeNow = () => {
        if(email !== "") {
            if(EmailRegex.test(email) === true) {
                const jsonData = JSON.stringify({ email: email.toLowerCase()});
                window.axios.post(SUBSCRIBE_API_URL, jsonData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }).then(function (result) {
                    toast.success(result.data.msg);
                    setErrorEmail("");
                    setEmail("");
                })  
                .catch(function (result) {
                    toast.error(result.response.data.error);
                });
            } else {
                if(EmailRegex.test(email) === false) {
                    setErrorEmail("Please Enter Valid Email id");
                } else {
                    setErrorEmail("");
                }
            }
        } else {
            if(email === "") {
                setErrorEmail("Email id cannot be empty");
            } else if(EmailRegex.test(email) === false) {
                setErrorEmail("Please Enter Valid Email id");
            } else {
                setErrorEmail("");
            }
        }
    };

    return(<React.Fragment>
        <footer className="footer_section">
            <div className="container">
                <div className="row" 
                    style={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}
                >
                    <div className="col-md-5">
                        <div className="col-6">
                            <div className="collinksfoot">
                                <ul>
                                    <li>
                                        <Link to={PRIVACY_POLICY} >Privacy Policy</Link>
                                    </li>
                                    <li>
                                        <Link to={TERMS_CONDITIONS} >Terms & Conditions</Link>
                                    </li>
                                    <li>
                                        <Link to={REFUND_POLICY}>Purchase & Refund Policy</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="collinksfoot">
                                <ul>
                                    <li>
                                        <Link to={CONTACT}>Contact</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12" style={{ float: "right" }}>
                            <div className="collinksfootsocial">
                                <ul>
                                    <li>
                                        <a href="javscript:void(0);">Facebook</a>
                                    </li>
                                    <li>
                                        <a href="javscript:void(0);">Instagram</a>
                                    </li>
                                    <li>
                                        <a href="javscript:void(0);">Twitter</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="footermailjoin">
                            <p>Join our mailing list and never miss an update</p>
                            <div className="footemialform">
                                <label>Email</label>
                                <div className="form-group">
                                    <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email here*" />
                                    {screen?.width < 600 && (<React.Fragment>{errorEmail && <div className="danger-colorset">{errorEmail}</div>}</React.Fragment>)}
                                    <Button className="btn" onClick={() => SubscribeNow()}>Subscribe Now</Button>
                                </div>
                                {screen?.width > 600 && (<React.Fragment>{errorEmail && <div className="danger-colorset">{errorEmail}</div>}</React.Fragment>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <div className="footercopyright">
            © 2023 Bundle of Books. All Rights Reserved.
        </div>
    </React.Fragment>)
}

export default Footer;