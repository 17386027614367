/* eslint-disable */
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Country, State, City }  from 'country-state-city';
import SucessIcon from "../../../assets/img/success.svg";
import ErrorIcon from "../../../assets/img/error.svg";
import { Button } from "react-bootstrap";
import CountrySelectInput from "../CountrySelectInput/CountryMobileInput";
import { customerDetailsGet, cardDetailsGet, AddMyProduct } from "../../../redux/actions";
import CustomMendotoryMsg from "../CustomMendotoryMsg";
import { useDispatch, useSelector } from "react-redux";
import { PLACE_PRODUCT_ORDER_API_URL, PAYMENT_PLACE_ORDER_API_URL } from "../../Shared/constant";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import Spinner from 'react-bootstrap/Spinner';

const CheckoutModal = (props) => {
    const { checkoutPoup, setCheckoutPoup, PosterMulti, SuccessPopup, setSuccessPopup, selectedProduct } = props;
    const { accessToken, customerDetail } = useSelector((state) => state.allReducers);
    const [ MobileNo, setMobileNo ] = useState({
        countrycode: "1",
        number: "",
    });
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [email, setEmail] = useState("");
    const [ SelectCountry, setSelectCountry ] = useState("United States");
    // mendotory error state
    const [ CustomError, setCustomError ] = useState(false);
    const [ MobileError, setMobileError ] = useState("");
    const [ stepUpdate, setStepUpdate ] = useState("yourdetails");
    const dispatch = useDispatch();
    const [ creditCard, setCreditCard ] = useState("");
    const [ creditCardError, setCreditCardError ] = useState("");
    const [ expiryDate, setExpiryDate ] = useState("");
    const [ cvv, setCVV ] = useState("");
    const [ cardholderName, setCardholderName ] = useState("");
    const [ paymentDropdown, setPaymentDropdown ] = useState(false);
    const [ errorEmail, setErrorEmail ] = useState("");
    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
    const [ errorExpiryDate, setErrorExpiryDate ] = useState("");

    const [ Loader, setLoader ] = useState(false);

    const [ showHideCvv, setShowHideCvv ] = useState(false);
    
    // select credit card
    const creditcardNo = (e) => {
        setCreditCard(e.target.value.trim().replace(/\s+/g, "").replace(/[^0-9]/gi, "").substr(0, 23));
    };

    const cc_format = (value) => {
        const v = value
          .replace(/\s+/g, "")
          .replace(/[^0-9]/gi, "")
          .substr(0, 23);
        const parts = [];
      
        for (let i = 0; i < v.length; i += 4) {
          parts.push(v.substr(i, 4));
        }
      
        return parts.length > 1 ? parts.join(" ") : value;
    }
    
    // select country 
    const SelectCoutry = (event) => {
        setSelectCountry(event.target.value);
    };

    // continue steps with order placed function
    const ProductId = selectedProduct.map((elm) =>  elm.id).toString();
    const PaymentContinueStep = () => {
        document.getElementById("checkoutflag")?.classList?.remove("active");
        if(fname !== "" && lname !== "" && email !== "" && MobileNo?.number?.length === 10 && SelectCountry !== "") {
            if(EmailRegex.test(email) === true) {
                setLoader(true);
                setCustomError(false);
                setErrorEmail("");
                setMobileError("");
                const jsonData = JSON.stringify({ 
                    "first_name" : fname, 
                    "last_name" : lname, 
                    "country" : SelectCountry, 
                    "email" : email.toLowerCase(), 
                    "phone" : MobileNo?.countrycode.toString() + " " + MobileNo?.number.toString(),
                    "cart" : ProductId,
                    "order_type": PosterMulti?.cart === 1 ? "single" : "bundle",
                    "total_amount": PosterMulti?.cart === 1 ? selectedProduct[0].price : PosterMulti?.amount,
                });
                window.axios.post(PLACE_PRODUCT_ORDER_API_URL, jsonData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + accessToken,
                    }
                }).then(function (result) {
                    setTimeout(()=> {
                        setLoader(false);
                        dispatch(customerDetailsGet(result.data.data));
                        setStepUpdate("paymentdetails");
                        // toast.success(result.data.msg);
                        setErrorEmail("");
                    }, 100);
                })  
                .catch(function (result) {
                    setLoader(false);
                    dispatch(customerDetailsGet(""));
                    toast.error(result.response.data.error);
                });
            } else {
                if(EmailRegex.test(email) === false) {
                    setErrorEmail("Please Enter Valid Email id");
                } else {
                    setErrorEmail("");
                }

                if(MobileNo?.number?.length < 10) {
                    setMobileError("Please enter 10 digit number");
                } else {
                    setMobileError("");
                }
            }
        } else {
            setCustomError(true);
            if(email === "") {
                setErrorEmail("Email id cannot be empty");
            } else if(EmailRegex.test(email) === false) {
                setErrorEmail("Please Enter Valid Email id");
            } else {
                setErrorEmail("");
            }
            if(MobileNo?.number === "") {
                setMobileError("Phone Number cannot be empty");
            } else if(MobileNo?.number?.length < 10) {
                setMobileError("Please enter 10 digit number");
            } else {
                setMobileError("");
            }
        };
    };

    const expiryDates = moment(expiryDate);
    const b = moment().utc();
    const expiryDatesdifrr = expiryDates.diff(b,'days');

    // card validations
    const MastercardRegex = /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/;
    const AmericanExpressRegex = /^3[47][0-9]{13}$/;
    const VisacardRegex = /^4[0-9]{12}(?:[0-9]{3})?$/;
    const DiscovercreditcardRegex = /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/;
    const MaestrocreditcardRegex = /^(5018|5081|5044|5020|5038|603845|6304|6759|676[1-3]|6799|6220|504834|504817|504645)[0-9]{8,15}$/;
    const JCBcreditcardRegex = /^(?:2131|1800|35[0-9]{3})[0-9]{11}$/;
    const DinercreditcardRegex = /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/;
    const CreditCardValid = MastercardRegex.test(creditCard) === true || AmericanExpressRegex.test(creditCard) === true || VisacardRegex.test(creditCard) === true || DiscovercreditcardRegex.test(creditCard) === true || MaestrocreditcardRegex.test(creditCard) === true || JCBcreditcardRegex.test(creditCard) === true || DinercreditcardRegex.test(creditCard) === true;

    // payment last step 
    const PaymentLastStep = () => {
        document.getElementById("checkoutflag")?.classList?.remove("active");
        if(CreditCardValid && (expiryDate !== "" && expiryDatesdifrr > 0) && cvv !== "" && cardholderName !== "") {
            if (expiryDatesdifrr > 0 && cvv?.length === 3){
                setLoader(true);
                setCustomError(false);
                const jsonData = JSON.stringify({ 
                    "card_number" : creditCard, 
                    "cardholder_name" : cardholderName, 
                    "order_id" : customerDetail?.id, 
                    "amount" : customerDetail?.total_amount, 
                    "ccexp": moment(expiryDate).format('MM/YY'),
                    "cvv": cvv,
                });
                window.axios.post(PAYMENT_PLACE_ORDER_API_URL, jsonData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + accessToken,
                    }
                }).then(function (result) {
                    setTimeout(()=> {
                        dispatch(cardDetailsGet(result.data.data));
                        dispatch(AddMyProduct(selectedProduct));
                        toast.success(result.data.msg);
                        setSuccessPopup({...SuccessPopup, open: true, title: "Thank you for the payment!"});
                        setCheckoutPoup({...checkoutPoup, open: false});
                        setErrorEmail("");
                        setErrorExpiryDate("");
                        setCreditCardError("");
                        setLoader(false);
                    }, 100);
                })  
                .catch(function (result) {
                    dispatch(cardDetailsGet(""));
                    
                    let ErrorMessage = result.response.data.error === undefined ? "" : result.response.data.error;
                    let ErrorMwsg = result.response.data.msg === undefined ? "" : result.response.data.msg;
                    toast.error(ErrorMessage + (ErrorMwsg === "" ? "" : ":") + ErrorMwsg);

                    setLoader(false);
                });
            } else{
                if(expiryDatesdifrr < 0) {
                    setErrorExpiryDate("Enter Valid Expiry date");
                } else {
                    setErrorExpiryDate("");
                }
            }
        } else {
            setCustomError(true);
            if(expiryDate !== "") {
                if(expiryDatesdifrr < 0) {
                    setErrorExpiryDate("Enter Valid Expiry date");
                } else {
                    setErrorExpiryDate("");
                }
            } else {
                setErrorExpiryDate("Expiry date cannot be empty");
            }
            if(creditCard === "") {
                setCreditCardError("Credit card number cannot be empty");
            } else if (CreditCardValid === false) {
                setCreditCardError("Enter valid number credit Card");
            } else {
                setCreditCardError("");
            }
        };
    };

    // onChange effect 
    const OnchangeNoGet = (e) => {
        if(e.target.value.length <= 3) {
            setCVV(e.target.value)
        }
    };

    // close checkout modal
    const CloseCheckoutModal = () => {
        document.getElementById("flagsDropdownid")?.classList?.remove("active");
        document.getElementById("checkoutflag")?.classList?.remove("active");
        setCheckoutPoup({...checkoutPoup, open: false})
    };

    // onchange get expiry date and validations
    const onGetExpirydate = (date) => {
        setExpiryDate(date);
        const expiryDates = moment(date);
        const b = moment().utc();
        const expiryDatesdifrr = expiryDates.diff(b,'days');
        if(date !== "") {
            if(expiryDatesdifrr < 0) {
                setErrorExpiryDate("Enter Valid Expiry date");
            } else {
                setErrorExpiryDate("");
            }
        } else {
            setErrorExpiryDate("Expiry date cannot be empty")
        }
    };

    const handleNumericInput = (event) => {
        event.target.value = event.target.value.replace(/[^0-9]/g, '');
    };

    // input field space issue 
    document.getElementById("CardholderNameID")?.addEventListener('keydown', function (e) {
        if (this.value.length === 0 && e.which === 32) e.preventDefault();
    });

    document.addEventListener("wheel", function(event){
        if(document.activeElement.type === "number" && document.activeElement.classList.contains("cvv_fileds")) {
            document.activeElement.blur();
        }
    });

    return(<div className="CheckoutModalWrapper">
            <button className="closeModal" onClick={() => CloseCheckoutModal()}>
                <svg viewBox="0 0 6 6" fill="currentColor" width="12px" height="12px"><path d="M5.2 0L3 2.2 0.8 0 0 0.8 2.2 3 0 5.3 0.8 6 3 3.8 5.2 6 6 5.3 3.8 3 6 0.8z"></path></svg>
            </button>
            <div className="paymentheadermain">
                <div className="pymenttitle">
                    {stepUpdate === "yourdetails" ? (<div>{checkoutPoup?.title}</div>) : (<div className="backpymentbtn" onClick={() => setStepUpdate("yourdetails")}><svg viewBox="0 0 18 18" fill="currentColor" width="18" height="18"><path d="M10.8448202,5.14270801 C11.0394183,5.33600267 11.0404716,5.64963633 10.8476779,5.84372938 L7.71273205,8.99980356 L10.8488003,12.1634729 C11.0414976,12.3578663 11.0408107,12.6714558 10.8472635,12.865003 C10.6532807,13.0582298 10.3404929,13.0576181 10.1479487,12.8643191 L6.29891136,9.00019644 L10.1421589,5.14494052 C10.3357619,4.95073257 10.649987,4.9497342 10.8448202,5.14270801 Z"></path></svg> Back</div>)}
                    <div className="dropdowncustome">
                        <div className="dropdowntoggle" onClick={() => setPaymentDropdown(!paymentDropdown)}>${PosterMulti?.cart === 1 ? selectedProduct[0].price : PosterMulti?.amount}
                            <svg viewBox="0 0 18 18" fill="currentColor" width="18" height="18"><path d="M3.96966991,5.96966991 C4.23593648,5.70340335 4.65260016,5.6791973 4.94621165,5.89705176 L5.03033009,5.96966991 L9.5,10.439 L13.9696699,5.96966991 C14.2359365,5.70340335 14.6526002,5.6791973 14.9462117,5.89705176 L15.0303301,5.96966991 C15.2965966,6.23593648 15.3208027,6.65260016 15.1029482,6.94621165 L15.0303301,7.03033009 L9.5,12.5606602 L3.96966991,7.03033009 C3.6767767,6.73743687 3.6767767,6.26256313 3.96966991,5.96966991 Z"></path></svg>
                        </div>    
                        {paymentDropdown && (<div className="amountdetailsdropdown">
                            <div className="posterdetails">
                                <span>{PosterMulti?.cart === 1 ? "Single Book" : "Bundle of " + PosterMulti?.cart + " Books"}</span>
                                <span>Quantity: 1</span>
                            </div>
                            <div>${PosterMulti?.cart === 1 ? selectedProduct[0].price : PosterMulti?.amount}</div>
                        </div>)}
                    </div>
                </div>
                <div className="paymentssteps">
                    <div className="active"></div>
                    <div className={stepUpdate === "paymentdetails" ? "active" : ""}></div>
                </div>
                <h4>{stepUpdate === "yourdetails" ? "1. Your Details" : "2. Payment Details"}</h4>
            </div>
        <div className="paymentWrappers">
            {stepUpdate === "yourdetails" ? (<div className="paymentforms">
                    <div className="rowcustom rowcustom-col-2">
                        <div className={CustomError && fname === "" ? "form-group error" : "form-group"}>
                            <label>First Name</label>
                            <div className="form-groupfiled">
                                <input type="text" className="form-control" value={fname} onChange={(e) => setFname(e.target.value.trim())} placeholder="Enter First Name" />
                                {fname !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                {CustomError && fname === "" && <CustomMendotoryMsg value={fname} label={"First name"} />}
                            </div>
                        </div>
                        <div className={CustomError && lname === "" ? "form-group error" : "form-group"}>
                            <label>Last Name</label>
                            <div className="form-groupfiled">
                                <input type="text" className="form-control" value={lname} onChange={(e) => setLname(e.target.value.trim())} placeholder="Enter Last Name" />
                                {lname !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                {CustomError && lname === "" && <CustomMendotoryMsg value={lname} label={"Last name"} />}
                            </div>
                        </div>
                    </div>
                    <div className="rowcustom rowcustom-col-2">
                        <div className={(errorEmail !== "" && EmailRegex.test(email) === false) ? "form-group error" : "form-group"}>
                            <label>Email</label>
                            <div className="form-groupfiled">
                                <input type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value.trim())} placeholder="Enter email" />
                                {EmailRegex.test(email) === true && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                {EmailRegex.test(email) === false && <div className="danger-color">{errorEmail}</div>}
                            </div>
                        </div>
                        <div className={CustomError && SelectCountry === "" ? "form-group error" : "form-group"}>
                            <label>Country</label>
                            <div className="form-groupfiled">
                                <select className="form-control" onChange={(e) => SelectCoutry(e)} defaultValue={SelectCountry} aria-label="Default select example">
                                    <option value="">Select Country</option>
                                    {Country.getAllCountries().map((elm, index) => {
                                        return(<option value={elm.name} key={index}>{elm.name}</option>)
                                    })}
                                </select>
                                {CustomError && SelectCountry === "" && <CustomMendotoryMsg value={SelectCountry} label={"Country"} />}
                            </div>
                        </div>
                    </div>
                    <div className="rowcustom">
                        <div className="col-md-12">
                            <div className={(MobileError !== "" && MobileNo?.number?.toString()?.length !== 10) ? "form-group error" : "form-group"}>
                                <label>Phone</label>
                                <div className="form-groupfiled">
                                    <CountrySelectInput 
                                        MobileNo={MobileNo}
                                        setMobileNo={setMobileNo}
                                        id={"checkoutflag"}
                                    />
                                    {MobileNo?.number?.toString()?.length === 10 && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                    {MobileNo?.number?.toString()?.length !== 10 && <div className="danger-color">{MobileError}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
            </div>) : (<div className="paymentforms paymentFormsWrapper">
                    <div className="rowcustom">
                        <div className="col-md-12">
                            <div className={creditCardError !== "" && CreditCardValid !== true ? "form-group error" : "form-group"}>
                                <label>Credit Card Number</label>
                                <div className="form-groupfiled">
                                    <input type="tel" className="form-control" value={cc_format(creditCard)} placeholder="Enter Credit Card Number" onChange={(e) => creditcardNo(e)} maxLength="23" pattern="\d*" />
                                    {CreditCardValid === true && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                    {CreditCardValid !== true && <div className="danger-color">{creditCardError}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rowcustom rowcustom-col-2">
                        <div className={errorExpiryDate !== "" ? "form-group error" : "form-group"}>
                            <label>Expiry Date</label>
                            <div className="form-groupfiled expirydatswrapper">
                                <input type="text" className="d-none"  />
                                <DatePicker
                                    selected={expiryDate}
                                    onChange={(date) => onGetExpirydate(date)}
                                    dateFormat="MM/yyyy"
                                    placeholderText="Select Expiry Date"
                                    autoComplete="off"
                                    showMonthYearPicker
                                />
                                {errorExpiryDate !== "" ? <img src={ErrorIcon} className="errorsuccessicon" alt={"icon"} /> : 
                                (errorExpiryDate === "" && expiryDate !== "") ? <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} /> : <React.Fragment></React.Fragment>}
                                {errorExpiryDate !== "" && <div className="danger-color">{errorExpiryDate}</div>}
                            </div>
                        </div>
                        <div className={CustomError && cvv === "" ? "form-group error" : "form-group"}>
                            <label>CVV</label>
                            <div className="form-groupfiled cvv-groupfiled">
                                <input type="password" className="d-none"  />
                                <input type={showHideCvv ? "number" : "password"} value={cvv} onChange={(e) => OnchangeNoGet(e)} className="form-control cvv_fileds" onInput={handleNumericInput} placeholder="Enter CVV" maxLength="3" autoComplete="off" />
                                <span className="input-group-text" onClick={() => setShowHideCvv(!showHideCvv)}>
                                    {showHideCvv ? <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M3 3l18 18"></path><path d="M10.584 10.587a2 2 0 0 0 2.828 2.83"></path><path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341"></path></svg> : <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" /></svg>}
                                </span>
                                {cvv?.length === 3 && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                {CustomError && cvv?.length !== 3 && <CustomMendotoryMsg value={cvv} label={"CVV"} />}
                            </div>
                        </div>
                    </div>
                    <div className="rowcustom">
                        <div className="col-md-12">
                            <div className={CustomError && cardholderName === "" ? "form-group error" : "form-group"}>
                                <label>Cardholder Name</label>
                                <div className="form-groupfiled">
                                    <input type="text" className="form-control" id="CardholderNameID" value={cardholderName} onChange={(e) => setCardholderName(e.target.value)} placeholder="Enter Cardholder Name" />
                                    {cardholderName !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                    {CustomError && cardholderName === "" && <CustomMendotoryMsg value={cardholderName} label={"Cardholder Name"} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
                <div className="rowcustom payloader">
                    <div className="col-md-12">
                        {stepUpdate === "yourdetails" ? (<Button className="btn formcomnbtn" disabled={Loader} onClick={() => PaymentContinueStep()}>{Loader && (<Spinner />)} Continue</Button>) :
                        (<Button className="btn formcomnbtn" disabled={Loader} onClick={() => PaymentLastStep()}>{Loader && (<Spinner />)} Pay Now</Button>)}
                        <div className="securepymentnote">
                            <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24"><path d="M9,9 L16,9 C17.1045695,9 18,9.8954305 18,11 L18,16 C18,17.6568542 16.6568542,19 15,19 L10,19 C8.34314575,19 7,17.6568542 7,16 L7,11 C7,9.8954305 7.8954305,9 9,9 Z M9,10 C8.44771525,10 8,10.4477153 8,11 L8,16 C8,17.1045695 8.8954305,18 10,18 L15,18 C16.1045695,18 17,17.1045695 17,16 L17,11 C17,10.4477153 16.5522847,10 16,10 L9,10 Z M12.5,5 C14.4329966,5 16,6.56700338 16,8.5 L16,9 L9,9 L9,8.5 C9,6.56700338 10.5670034,5 12.5,5 Z M12.5,6 C11.1192881,6 10,7.11928813 10,8.5 L10,9 L15,9 L15,8.5 C15,7.11928813 13.8807119,6 12.5,6 Z M13,13 L14,13 L14,14 L13,14 L13,16 L12,16 L12,14 L11,14 L11,13 L12,13 L12,12 L13,12 L13,13 Z"></path></svg>
                            SSL Secure Payment
                        </div>
                    </div>
                </div>
            </div>
    </div>)
}

export default CheckoutModal;